<template>
  <div id="mian">
    <div class="header" v-if="pageType == 'edit'">
      <span @click="toTypeUrl('applet_list_detail')" id="fzcolor">基本信息</span>
      <span @click="toTypeUrl('applet_list_detail_set')">配置信息</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">基本信息</h5>
    </div>

    <!-- 快速创建小程序 -->
    <div class="input_con" v-show="formData.appType == 'QUICK_CREATE'">
      <ul class="inputBox">
        <li>
          <span><i>*</i>小程序主体名称</span>
          <el-input class="inputs" v-model="formData.principalName" maxlength="128" placeholder="输入小程序主体名称"
            tabindex="1"></el-input>
        </li>
        <li>
          <span><i>*</i>所属第三方</span>
          <el-select class="inputs" v-model="formData.thirdId" placeholder="选择所属第三方" tabindex="2">
            <el-option v-for="(v, i) in thirdDrop" :key="i" :label="v.thirdName" :value="v.thirdId">
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>企业代码类型</span>
          <el-select class="inputs" v-model="formData.enterpriseCodeType" placeholder="选择企业代码类型" tabindex="3">
            <el-option label="统一社会信用代码" value="1"></el-option>
            <el-option label="组织机构代码" value="2"></el-option>
            <el-option label="营业执照注册号" value="3"></el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>企业代码</span>
          <el-input class="inputs" v-model="formData.enterpriseCode" maxlength="128" placeholder="输入企业代码"
            tabindex="4"></el-input>
        </li>
        <li>
          <span><i>*</i>法人姓名</span>
          <el-input class="inputs" v-model="formData.legalPersonName" maxlength="128" placeholder="输入法人姓名"
            tabindex="5"></el-input>
        </li>
        <li>
          <span><i>*</i>法人微信</span>
          <el-input class="inputs" v-model="formData.legalPersonWechat" maxlength="128" placeholder="输入法人微信"
            tabindex="6"></el-input>
        </li>
      </ul>
    </div>

    <!-- 新增复用复用创建时 -->
    <div class="input_con" v-show="formData.appType == 'REUSE'">
      <ul class="inputBox">
        <li>
          <span><i>*</i>小程序主体名称</span>
          <el-input class="inputs" v-model="formData.principalName" maxlength="128" placeholder="输入小程序主体名称"
            tabindex="1"></el-input>
        </li>
        <li>
          <span><i>*</i>所属第三方</span>
          <el-select :disabled="pageType == 'edit'" class="inputs" v-model="formData.thirdId" placeholder="选择所属第三方"
            tabindex="2">
            <el-option v-for="(v, i) in thirdDrop" :key="i" :label="v.thirdName" :value="v.thirdId">
            </el-option>
          </el-select>
        </li>
        <li>
          <span><i>*</i>公众号APPID</span>
          <el-input :disabled="pageType == 'edit'" class="inputs" v-model="formData.wxAppId" maxlength="128"
            placeholder="输入公众号APPID" tabindex="3"></el-input>
        </li>
      </ul>
    </div>

    <!-- 新增商户创建小程序时 -->
    <div class="input_con" v-show="formData.appType == 'MERCHANT'">
      <ul class="inputBox">
        <li>
          <span>小程序主体名称</span>
          <el-input class="inputs" disabled v-model="formData.principalName" maxlength="128"
            placeholder="输入小程序主体名称" tabindex="1"></el-input>
        </li>
        <li>
          <span><i>*</i>所属第三方</span>
          <el-select :disabled="pageType == 'edit'" class="inputs" v-model="formData.thirdId" placeholder="选择所属第三方"
            tabindex="2">
            <el-option v-for="(v, i) in thirdDrop" :key="i" :label="v.thirdName" :value="v.thirdId">
            </el-option>
          </el-select>
        </li>
        <li>
          <span>小程序名称</span>
          <el-input class="inputs" disabled v-model="formData.appName" maxlength="128"
            placeholder="输入小程序名称" tabindex="2"></el-input>
        </li>
        <li>
          <span>APPID</span>
          <el-input class="inputs" disabled v-model="formData.appId" maxlength="128"
            placeholder="输入APPID" tabindex="3"></el-input>
        </li>
        <li>
          <span>原始ID</span>
          <el-input class="inputs" disabled v-model="formData.originalId" maxlength="128"
            placeholder="输入原始ID" tabindex="4"></el-input>
        </li>
        <li>
          <span>APPsecret</span>
          <el-input class="inputs" disabled v-model="formData.appSecret" maxlength="128"
            placeholder="输入APPsecret" tabindex="5"></el-input>
        </li>
      </ul>
    </div>
    <div class="btn">
      <el-button @click="back" class="back">取消</el-button>

      <el-button @click="codeImg" class="next" type="primary"
        v-show="formData.appType == 'REUSE' && pageType == 'edit'">生成授权页</el-button>
      
      <el-button v-if="formData.appType !== 'MERCHANT'" @click="submit" class="next" type="primary">完成</el-button>

      <el-button v-if="formData.appType == 'MERCHANT' && formData.smallId" class="next" type="primary" @click="completeBtn">完成</el-button>

      <el-button v-if="formData.appType == 'MERCHANT' && !formData.smallId" class="next" type="primary" @click="submit">去授权</el-button>
    </div>

    <div class="cover_view" v-if="showImgUrl !== ''" @click="showImgUrl = ''">
      <a href="javascript:;">
        <img :src="showImgUrl" alt="加载失败" />
      </a>
    </div>
  </div>
</template>
<script>
import { setApps, getApps, getAppsAuthqrcode, authCodeCb } from "@/api/user/applet.js";
import { thirdsDrop } from "@/api/common.js";
export default {
  data() {
    return {
      formData: {
        appId: "",
        smallId: 0,
        appType: "QUICK_CREATE",
        thirdId: "",
        appName: "",
        principalName: "",
        enterpriseCodeType: "",
        enterpriseCode: "",
        legalPersonName: "",
        legalPersonWechat: "",
        wxAppId: "",
        originalId: "",
        appSecret: "",
      },
      thirdDrop: [],
      pageType: "", //页面状态
      showImgUrl: "",
    };
  },
  created() {
    if (this.$route.query.pageType == "edit") {
      this.pageType = "edit";
      this.getDetail();
    }
    if (this.$route.query.status) {
      this.formData.appType = this.$route.query.status;
    }
    this.getDrop();

    const { auth_code, expires_in , thirdId } = this.$route.query;
    if (auth_code && expires_in && thirdId) {
      this.auth(auth_code, expires_in, thirdId);
    }
  },
  methods: {
    // 授权回调
    auth(auth_code, expires_in,thirdId) {
      const data = {
        auth_code,
        expires_in,
        thirdId
      };
      authCodeCb(data).then((res) => {
        if (res.resultStatus) {
          this.formData = res.resultData;
        }
      });
    },
    // 获取详情
    getDetail() {
      getApps(this.$route.query.id).then((res) => {
        if (res) {
          this.formData = res.resultData;
          this.formData.thirdId = res.resultData.thirdName;
        }
      });
    },
    // 获取下拉
    getDrop() {
      thirdsDrop().then((res) => {
        if (res) {
          this.thirdDrop = res.resultData;
        }
      });
    },
    // 表单规则
    roles() {
      let data = JSON.parse(JSON.stringify(this.formData));
      console.log("表单验证的数据", data);
      if (data.appType == "QUICK_CREATE") {
        if (!data.appType) {
          this.$message.error("APP类型必传");
          return false;
        }
        if (!data.principalName) {
          this.$message.error("主体名称必填");
          return false;
        }
        if (!data.thirdId) {
          this.$message.error("所属第三方必选");
          return false;
        }
        if (!data.enterpriseCodeType) {
          this.$message.error("企业代码类型必选");
          return false;
        }
        if (!data.enterpriseCode) {
          this.$message.error("企业代码必填");
          return false;
        }
        if (data.enterpriseCodeType == 1 && data.enterpriseCode.length != 18) {
          this.$message.error("企业代码为18位");
          return false;
        }
        if (
          data.enterpriseCodeType == 2 &&
          !/^\S{8}-\S{1}$/.test(data.enterpriseCode)
        ) {
          this.$message.error("企业代码为9位(xxxxxxxx-x)");
          return false;
        }
        if (data.enterpriseCodeType == 3 && data.enterpriseCode.length != 15) {
          this.$message.error("企业代码为15位");
          return false;
        }
        if (!data.legalPersonName) {
          this.$message.error("法人姓名必填");
          return false;
        }
        if (!data.legalPersonWechat) {
          this.$message.error("法人微信必填");
          return false;
        }
      }
      if (data.appType == "REUSE") {
        if (!data.appType) {
          this.$message.error("APP类型必传");
          return false;
        }
        if (!data.principalName) {
          this.$message.error("主体名称必填");
          return false;
        }
        if (!data.thirdId) {
          this.$message.error("所属第三方必选");
          return false;
        }
        if (!data.wxAppId) {
          this.$message.error("公众号APPID必填");
          return false;
        }
      }
      if (data.appType == "MERCHANT") {
        // if (!data.appType) {
        //   this.$message.error("APP类型必传");
        //   return false;
        // }
        // if (!data.principalName && this.pageType == "edit") {
        //   this.$message.error("主体名称必填");
        //   return false;
        // }
        if (!data.thirdId) {
          this.$message.error("所属第三方必选");
          return false;
        }
        // if (this.pageType == "edit") {
        //   if (!data.appName) {
        //     this.$message.error("小程序名称必填");
        //     return false;
        //   }
        //   if (!data.appId) {
        //     this.$message.error("APPID必填");
        //     return false;
        //   }
        //   if (!data.originalId) {
        //     this.$message.error("原始ID必填");
        //     return false;
        //   }
        //   if (!data.appSecret) {
        //     this.$message.error("APPSecret必填");
        //     return false;
        //   }
        // }
      }
      return true;
    },
    completeBtn(){
      this.$router.push({ name: "applet_list", });
    },
    // 提交
    submit() {
      if (!this.roles()) {
        return;
      }
      for (let i = 0; i < this.thirdDrop.length; i++) {
        if (this.formData.thirdId == this.thirdDrop[i].thirdName) {
          this.formData.thirdId = this.thirdDrop[i].thirdId;
        } else {
          this.formData.thirdId = this.formData.thirdId;
        }
      }
      delete this.formData.createTime;

      let data = JSON.parse(JSON.stringify(this.formData));

      // console.log("提交的数据为", this.formData);
      // return;

      if (this.formData.appType == "MERCHANT" && this.pageType == "edit") {
        return this.$router.push({ name: "applet_list", });
      }

      setApps(data).then((res) => {
        if (res.resultStatus) {
          if (this.formData.appType == "MERCHANT" && this.pageType != "edit") {
            window.open(res.resultData.preUrl);
          } else {
            this.$message.success("保存成功");
            if (this.$route.query.pageType == "edit") {
              this.$router.go(-1);
            } else {
              this.$router.push({ name: "applet_list", });
            }
          }
        }
      });
    },
    // 授权
    codeImg() {
      getAppsAuthqrcode(this.$route.query.id).then((res) => {
        if (res) {
          this.showImgUrl =
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
        }
      });
    },
    // 页面修改状态的跳转
    toTypeUrl(name) {
      this.$router.push({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          status: this.formData.appType,
        },
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/editInput.css";

.tab1 {
  min-height: auto !important;
}

.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: rgba(72, 184, 182, 1);
  border-color: #5aafab;
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: #333333;
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}
</style>
